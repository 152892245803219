// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media(hover:hover){a.\\+l1ji9Qg:hover{border-color:var(--color-gray-3)}}@media(hover:none){a.\\+l1ji9Qg:active{border-color:var(--color-gray-3)}}.w0CQwulP{margin:0 0 var(--spacing-sm)}@media(max-width:947.98px){.w0CQwulP{height:auto!important}}@media(min-width:947.98px){.w0CQwulP{display:flex;margin:0;padding:0 calc(var(--spacing-sm)/2);position:absolute;transition:height .15s;width:100%}.OspNY1hz:hover{z-index:2}}.\\+l1ji9Qg{background-color:var(--color-gray-2);border:1px solid transparent;border-radius:var(--border-radius);box-shadow:0 0 0 rgba(var(--color-shadow-rgb),0);display:flex;flex-direction:column;flex-grow:1;height:calc(100% - var(--spacing-sm));overflow:hidden;padding:var(--spacing-md);position:relative;transition:.15s}.OspNY1hz:hover{height:var(--height)!important}.OspNY1hz:hover .\\+l1ji9Qg{box-shadow:0 4px 20px rgba(var(--color-shadow-rgb) 12%)}.OspNY1hz:hover .\\+l1ji9Qg:after{opacity:0;visibility:hidden}.LmBRt04L{stroke:var(--color-primary);stroke-width:1px;box-sizing:content-box;color:var(--color-body);cursor:pointer;height:20px;margin-left:auto;padding:var(--spacing-sm);position:absolute;right:0;top:4px;transition:color .15s,stroke .15s,stroke-width .15s;width:20px}.LmBRt04L:hover{stroke:var(--color-primary-hover);stroke-width:2px}.VGZ\\+Gz2o{margin-left:0;padding:var(--spacing-md);position:static}.cJeBn1Cb{color:var(--color-gray-4);font-size:.81rem;font-weight:500;line-height:1.38;padding:0 0 .15em}.WDhiM5iL{word-wrap:break-word;color:var(--color-bg-invert);font-size:.88rem;font-weight:500;margin:0;padding:0 0 var(--spacing-xs);padding-right:calc(var(--spacing-md)*3);transition:color .15s}@media(min-width:947.98px){.WDhiM5iL{font-size:1rem}}._5Nymanpt{word-wrap:break-word;color:var(--color-gray-4);font-size:.88rem;-webkit-hyphens:auto;hyphens:auto}.DIamn0LU{padding:var(--spacing-md);position:absolute;right:0;top:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"sessionCard": "+l1ji9Qg",
	"session": "w0CQwulP",
	"sessionOverflow": "OspNY1hz",
	"sessionIcon": "LmBRt04L",
	"sessionIconModal": "VGZ+Gz2o",
	"sessionTime": "cJeBn1Cb",
	"sessionTitle": "WDhiM5iL",
	"sessionText": "_5Nymanpt",
	"fav": "DIamn0LU"
};
module.exports = ___CSS_LOADER_EXPORT___;
