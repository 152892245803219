
import filter from "lodash/filter"
import flatten from "lodash/flatten"
import get from "lodash/get"
import map from "lodash/map"
import sortBy from "lodash/sortBy"
import uniqBy from "lodash/uniqBy"

export default {
  props: {
    groups: { type: Array, default: () => [] },
    sessions: { type: Array, default: () => [] },
  },

  data: () => ({
    factor: 5,
  }),

  computed: {
    activeGroup () {
      const activeGroup = this.groups.length ? this.groups[0].alias : null
      return get(this.$route.query, "group", activeGroup)
    },

    activeHall () {
      const activeHall = this.halls.length ? this.halls[0].alias : null
      return get(this.$route.query, "hall", activeHall)
    },

    halls () {
      const halls = map(this.sessions, "track.hall")
      return sortBy(uniqBy(halls, "alias"), "alias")
    },

    displaySessions () {
      if (!this.activeGroup) return []
      const sessions = this.halls.map(hall =>
        filter(this.sessions, ({ track }) =>
          track.hall.alias === hall.alias && track.group.alias === this.activeGroup,
        ))
      return sortBy(sessions, "start").filter(item => item.length)
    },

    displayHalls () {
      return this.displaySessions.map(session => ({
        ...session[0].track.hall,
        description: session[0].track.description,
      }))
    },

    programStart () {
      return Math.min(...map(flatten(this.displaySessions), "start"))
    },

    programFinish () {
      return Math.max(...map(flatten(this.displaySessions), "finish"))
    },

    programLength () {
      const length = this.timeToHeight(this.programFinish - this.programStart)
      return length
    },

    timeline () {
      const timeline = []
      let date = new Date(this.programStart)
      const startMinutes = Math.floor((date.getHours() * 60 + date.getMinutes()) / 30) * 30
      date.setHours(startMinutes / 60)
      date.setMinutes(startMinutes % 60)
      date = date.getTime()
      while (date <= this.programFinish) {
        timeline.push(date)
        date = date + 30 * 60 * 1000
      }
      return timeline
    },
  },

  methods: {
    activeSessionGroup (alias) {
      return this.groups.find(entry => entry.alias === alias)
    },

    timeToHeight (time) {
      return Math.floor(time / 60 / 1000 * this.factor)
    },

    sessionTop (session) {
      const result = this.timeToHeight(session.start - this.programStart)
      return `${result}px`
    },

    sessionHeight (session) {
      const result = this.timeToHeight(session.finish - session.start)
      return `${result}px`
    },
  },
}
